import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AnchorLink from "../components/anchorLink"

const resources = [
    {
        name: "3 AI design guidelines from industry leaders",
        url: "/AI-design-guidelines-from-industry-leaders/",
    },
    {
        name: "4 Exceptional motion design tools for UI designers",
        url: "/4-motion-design-tools-to-elevate-your-ui-design",
    },
    {
        name: "6 Free resources to learn UX research",
        url: "/6-free-resources-to-learn-ux-research",
    },
    {
        name: "Learn how to create illustrations in Figma",
        url: "/learn-to-create-illustrations-in-figma",
    },
    {
        name: "Figma videos for beginners",
        url: "/best-youtube-videos-to-get-started-with-figma",
    },
    {
        name: "Learn UI animation & prototyping",
        url: "/free-videos-to-learn-ui-animation-prototyping",
    },
    {
        name: "Best documentaries for designers",
        url: "/5-must-watch-documentaries-for-designers",
    },
    {
        name: "Free design resources",
        url: "/best-free-resources-for-designers-in-2020",
    },
    {
        name: "6 completely free websites to find unique photos.",
        url: "/6-free-unique-photo-websites",
    },
    {
        name: "Design guidelines for top OS",
        url: "/official-design-guidelines-and-resources-for-top-os-2020",
    },
    {
        name: "24 blogs for designers",
        url: "/24-best-blogs-for-designers",
    },
    {
        name: "Best books on web design",
        url: "/6-best-books-on-web-design-you-should-read-in-2020",
    },
    {
        name: "7 UX design podcasts for beginners",
        url: "/7-ux-design-podcasts-for-beginners",
    },
    {
        name: "5 fresh Google font pairs",
        url: "/5-fresh-google-font-combinations",
    },
    {
        name: "6 must-have Figma plugins",
        url: "/6-must-have-figma-plugins-to-supercharge-your-workflow",
    },
]

function TopResourcesPage({ location }) {
    return (
        <Layout location={location}>
            <Seo title="Best UI & UX design resources" description="UX Choice resources is a curated library of best UI and UX design resources. Find the best books, inspiration, quotes, articles, videos, tutorial and more for UI, UX, and product designers" />
            <main>
                <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
                    <h2 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 my-16">Recommended readings</h2>
                    <div className="mb-16">
                        <ul className="space-y-10">
                            {resources.map(resource => (
                                <li key={resource.url}>
                                    <AnchorLink
                                        to={resource.url}
                                        className="text-2xl md:text-3xl leading-tight font-bold text-gray-900 tracking-tight underline"
                                    >
                                        {resource.name}
                                    </AnchorLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default TopResourcesPage
